// UsersContext.js

import React, { createContext, useState, useEffect } from 'react';
import { db } from '../utils/firebaseConfig';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';

export const UsersContext = createContext();

export const UsersProvider = ({ children }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCol = collection(db, 'users');
        const q = query(usersCol, orderBy("username"));
        const userSnapshot = await getDocs(q);
        const fetchedUsers = userSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setUsers(fetchedUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <UsersContext.Provider value={{ users }}>
      {children}
    </UsersContext.Provider>
  );
};

export default UsersContext;

