// ManagePosts.js

import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { doc, updateDoc } from "firebase/firestore";
import { db } from '../utils/firebaseConfig';
import './ManagePosts.css';
import PostsContext from '../contexts/PostsContext';

const ManagePosts = () => {
  const { posts: globalPosts, deletePostById, updateSinglePost } = useContext(PostsContext);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    setPosts(globalPosts);
  }, [globalPosts]);

  const handleDelete = async (postId) => {
    if (window.confirm("Are you sure you want to delete this post?")) {
      await deletePostById(postId);
    }
  };

  const handleShowOnHomePageChange = async (post, showOnHomePage) => {
    const postRef = doc(db, "posts", post.id);
    await updateDoc(postRef, { showOnHomePage });
    updateSinglePost({ ...post, showOnHomePage });
  };

  const placeholderImageUrl = 'https://via.placeholder.com/150';

  return (
    <div>
      <h2>Manage Posts</h2>
      <div>Total Posts: {posts.length}</div>
      <ul className="post-list">
        {posts.map(post => (
          <li key={post.id} className="post-item">
            <img src={post.imageUrl || placeholderImageUrl} alt={post.title || "Post image"} className="post-thumbnail" />
            <Link to={`/posts/${post.id}`} className="post-title">{post.title}</Link>
            <input 
              type="checkbox" 
              checked={post.showOnHomePage || false} 
              onChange={(e) => handleShowOnHomePageChange(post, e.target.checked)} 
            />
            <button onClick={() => handleDelete(post.id)} className="button-delete">Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ManagePosts;
