// ForgotPasswordPage.js

import React, { useState, useEffect } from 'react'; // Import useEffect
import { Link, useNavigate } from 'react-router-dom';
import './ForgotPasswordPage.css';
import { auth } from '../utils/firebaseConfig';
import { sendPasswordResetEmail } from '@firebase/auth';

const ForgotPasswordPage = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(''); // To notify user after reset email has been sent
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top when the component mounts
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await sendPasswordResetEmail(auth, email);
            setMessage('Password reset email sent. Please check your inbox.');
        } catch (err) {
            console.error("Error sending password reset email:", err);
            setError(err.message);
        }
    };

    return (
        <div className="forgotpassword-container">
            <div className="forgotpassword-header">Reset Password</div>
            {message && <p className="message-info">{message}</p>}
            {error && <p className="error-message">{error}</p>}
            <input 
                type="email" 
                placeholder="Email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                className="forgotpassword-input"
            />
            <button className="forgotpassword-button" onClick={handleSubmit}>Send Reset Email</button>
            
            <div className="forgotpassword-links">
                <div className="forgotpassword-text">
                    Remember your password? <Link to="/login">Login</Link>
                </div>
            </div>
        </div>
    );
}

export default ForgotPasswordPage;

