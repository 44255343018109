/* ProfilePage.js */

import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getPostsByUserId, getUserById } from '../utils/dbUtilities';
import { Link, Navigate } from 'react-router-dom'; // Use Navigate instead of Redirect
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import './ProfilePage.css';

const ProfilePage = () => {
  const { currentUser } = useAuth();
  const [userPosts, setUserPosts] = useState([]);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    async function fetchUserData() {
      if (currentUser) {
        try {
          const fetchedUserData = await getUserById(currentUser.uid);
          setUserData(fetchedUserData);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    }

    async function fetchUserPosts() {
      if (currentUser) {
        try {
          const userSpecificPosts = await getPostsByUserId(currentUser.uid);
          const sortedUserPosts = userSpecificPosts
            .map(post => ({
              ...post,
              timestamp: post.timestamp?.toDate ? post.timestamp.toDate() : new Date(post.timestamp)
            }))
            .sort((a, b) => b.timestamp - a.timestamp);
          setUserPosts(sortedUserPosts);
        } catch (error) {
          console.error("Error fetching user's posts:", error);
        }
      }
    }    

    fetchUserData();
    fetchUserPosts();
  }, [currentUser]);

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="profile-container">
      <div className="profile-header">
        <div className="profile-picture-container">
          <AccountCircleIcon className="profile-picture" style={{ fontSize: 150 }} />
          <div className="user-info">
            <h2 className="user-name">{userData ? userData.displayName : ''}</h2>
            <p className="post-count">{userPosts.length} Posts</p>
          </div>
        </div>
      </div>
      <div className="explore-grid">
        {userPosts.map((post, idx) => (
          <div key={idx} className="explore-item">
            <Link to={`/posts/${post.id}`}>
              <img 
                src={post.imageUrl || post.image || "https://via.placeholder.com/300x300"}
                alt={post.title || "User Post"}
                style={{ width: '100%', height: '100%' }}
              />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProfilePage;
