// contexts/BlockContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { db } from '../utils/firebaseConfig';
import { doc, updateDoc, getDoc, onSnapshot } from 'firebase/firestore';
import { useAuth } from './AuthContext';

const BlockContext = createContext();

export const useBlock = () => {
  return useContext(BlockContext);
};

export const BlockProvider = ({ children }) => {
  const [blockedUsers, setBlockedUsers] = useState({});
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser?.uid) {
      const userRef = doc(db, 'users', currentUser.uid);

      // Subscribe to user document changes
      const unsubscribe = onSnapshot(userRef, (userDoc) => {
        if (userDoc.exists()) {
          setBlockedUsers(userDoc.data().blockedUsers || {});
        }
      }, (error) => {
        console.error("Error fetching blocked users:", error);
      });

      // Clean up subscription on unmount
      return () => unsubscribe();
    }
  }, [currentUser]);

  const blockUser = async (username) => {
    if (!currentUser?.uid) {
      console.error("No current user found for blocking operation");
      return;
    }

    const userRef = doc(db, 'users', currentUser.uid);
    await updateDoc(userRef, { [`blockedUsers.${username}`]: true });
  };

  const unblockUser = async (username) => {
    if (!currentUser?.uid) {
      console.error("No current user found for unblocking operation");
      return;
    }

    const userRef = doc(db, 'users', currentUser.uid);
    await updateDoc(userRef, { [`blockedUsers.${username}`]: false });
  };

  const isUserBlocked = (username) => {
    return !!blockedUsers[username];
  };

  return (
    <BlockContext.Provider value={{ blockedUsers, blockUser, unblockUser, isUserBlocked }}>
      {children}
    </BlockContext.Provider>
  );
};

export default BlockContext;
