// PostsContext.js

import React, { createContext, useState, useEffect } from 'react';
import {
  getLikesForPost,  
  getReportedPosts,  
  getUserLikes,
  likePost,
  unlikePost,
  deletePostFromDB,
  updatePostInDB
} from '../utils/dbUtilities';
import { useAuth } from './AuthContext';
import { db } from '../utils/firebaseConfig';
import { collection, query, orderBy, startAfter, limit, getDocs } from 'firebase/firestore';

export const PostsContext = createContext();

export const PostsProvider = ({ children }) => {
  const [posts, setPosts] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const { currentUser } = useAuth();

  const sortPostsByTimestamp = (postsArray) => {
    return postsArray.sort((a, b) => {
      const timestampA = a.timestamp instanceof Date ? a.timestamp.getTime() : new Date(a.timestamp).getTime();
      const timestampB = b.timestamp instanceof Date ? b.timestamp.getTime() : new Date(b.timestamp).getTime();
      return timestampB - timestampA;
    });
  };

  const fetchAndUpdatePosts = async () => {
    try {
      let postQuery = query(collection(db, 'posts'), orderBy('timestamp', 'desc'), limit(10));
  
      if (lastVisible) {
        postQuery = query(collection(db, 'posts'), orderBy('timestamp', 'desc'), startAfter(lastVisible), limit(10));
      }
  
      const snapshot = await getDocs(postQuery);
      const fetchedPosts = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Deduplicate right after fetching
      const uniquePosts = Array.from(new Map(fetchedPosts.map(post => [post.id, post])).values());

      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      // Ensure overall uniqueness when setting posts
      setPosts(prevPosts => {
        const allPosts = [...prevPosts, ...uniquePosts];
        return Array.from(new Map(allPosts.map(post => [post.id, post])).values());
      });
    } catch (error) {
      console.error('Error fetching and updating posts:', error);
    }
  };  

  useEffect(() => {
    fetchAndUpdatePosts();
  }, [currentUser]);

  const addNewPost = (newPost) => {
    setPosts(currentPosts => sortPostsByTimestamp([newPost, ...currentPosts]));
  };

  const updateSinglePost = async (updatedPostData) => {
    console.log("Updating single post in context with data:", updatedPostData);
  
    if (!updatedPostData.imageUrl) {
      console.error('No imageUrl provided for updateSinglePost:', updatedPostData);
      // You may want to handle this case specifically.
    }
  
    try {
      // Attempt to update the post in the database
      await updatePostInDB(updatedPostData.id, updatedPostData);
      // If successful, update the local state
      setPosts(currentPosts => currentPosts.map(post => post.id === updatedPostData.id ? { ...post, ...updatedPostData } : post));
      console.log("Post successfully updated in context and database");
    } catch (error) {
      console.error('Error updating post in Firestore:', error);
        return; // Exit the function if the Firestore update fails
    }

    // Update the local state
    setPosts(currentPosts =>
        currentPosts.map(post =>
            post.id === updatedPostData.id ? { ...post, ...updatedPostData } : post
        )
    );
};
  
  const updatePostLikeStatus = (postId, isLiked, likeCount) => {
    setPosts(currentPosts => 
      currentPosts.map(post => {
        if (post.id === postId) {
          return {
            ...post,
            liked: isLiked,
            likes: likeCount,
          };
        }
        return post;
      })
    );
  };  

  const updateReportedStatus = async (postId, isReported) => {
    setPosts(currentPosts => 
      currentPosts.map(post => 
        post.id === postId ? { ...post, isReported: isReported } : post
      )
    );
    await updatePostInDB(postId, { reported: isReported });
  };  

  const likePostById = async (postId) => {
    if (currentUser) {
      try {
        await likePost(currentUser.uid, postId);
        const updatedLikes = await getLikesForPost(postId);
        updatePostLikeStatus(postId, true, updatedLikes.length);
      } catch (error) {
        console.error('Error liking post:', error);
      }
    }
  };

  const unlikePostById = async (postId) => {
    if (currentUser) {
      try {
        await unlikePost(currentUser.uid, postId);
        const updatedLikes = await getLikesForPost(postId);
        updatePostLikeStatus(postId, false, updatedLikes.length);
      } catch (error) {
        console.error('Error unliking post:', error);
      }
    }
  };

  const deletePostById = async (postId) => {
    console.log(`Attempting to delete post with ID: ${postId}`);
    try {
      await deletePostFromDB(postId);
      console.log(`Post with ID: ${postId} successfully deleted from Firestore`);
      setPosts(posts => posts.filter((post) => post.id !== postId));
      console.log(`Local state updated. Current posts:`, posts);
    } catch (error) {
      console.error(`Error deleting post with ID: ${postId}`, error);
    }
  };  

  // Function to remove an image from a post
  const removeImageFromPost = (postId) => {
    setPosts(currentPosts =>
      currentPosts.map(post =>
        post.id === postId ? { ...post, imageUrl: null } : post
      )
    );
  };

  const value = {
    posts,
    setPosts,
    fetchAndUpdatePosts,
    likePostById,
    unlikePostById,
    updatePostLikeStatus,
    addNewPost,
    updateReportedStatus,
    updateSinglePost,
    deletePostById,
    removeImageFromPost, // Expose the function to consumers
  };

  return <PostsContext.Provider value={value}>{children}</PostsContext.Provider>;
};

export default PostsContext;
