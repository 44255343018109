// src/components/ProtectedRoute.js

import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext"; // Import useAuth from AuthContext

export const ProtectedRoute = ({ children, allowedRoles }) => {
  const { currentUser } = useAuth(); // Updated to currentUser

  if (!currentUser) {
    // User is not authenticated
    return <Navigate to="/login" />;
  }

  if (allowedRoles && !allowedRoles.includes(currentUser.role)) {
    // User does not have the required role
    return <Navigate to="/unauthorized" />;
  }

  return children;
};
