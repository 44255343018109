// CloseButton.js

import React from 'react';
import './CloseButton.css';

const CloseButton = ({ onClick }) => {
  return (
    <button className="close-button" onClick={onClick}>
      X
    </button>
  );
};

export default CloseButton;
